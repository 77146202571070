<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <!-- <img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto"> -->
                        </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Reset Password</h4>
                  <p>Please enter your new password.</p>
                  <vs-alert
                    :active.sync="showDismissibleAlert"
                    closable
                    icon-pack="feather"
                    close-icon="icon-x"
                    :color="alertColor"
                  >{{message}}</vs-alert>
                </div>
                <form>
                  <vs-input
                        :danger="errors.first('password')"
                        :success="!errors.first('password') && user.password!=''"
                        val-icon-success="done"
                        val-icon-danger="error"
                        ref="password"
                        type="password"
                        data-vv-name="password"
                        data-vv-validate-on="blur"
                        v-validate="{required:true,min:8,regex:/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9._*\W]+$)/}"
                        name="password"
                        label-placeholder="Password"
                        placeholder="Password"
                        v-model="user.password"
                        class="w-full mt-6"
                        :danger-text="errors.first('password')" />

                      <vs-input
                        :danger="errors.first('confirm_password')"
                        :success="!errors.first('confirm_password') && user.confirmPassword!=''"
                        val-icon-success="done"
                        val-icon-danger="error"
                        type="password"
                        v-validate="'required|confirmed:password'"
                        data-vv-validate-on="blur"
                        data-vv-as="password"
                        name="confirm_password"
                        label-placeholder="Confirm Password"
                        placeholder="Confirm Password"
                        v-model="user.confirmPassword"
                        class="w-full mt-6" 
                        :danger-text="errors.first('confirm_password')" />
                </form>
             <br />
                <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row">
                  <!--                                    <vs-button type="border" to="/pages/login" class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto">Go Back To Login</vs-button>-->
                  <vs-button
                    class="w-full sm:w-auto"
                    @click="resetPassword"
                  >Reset</vs-button>
                </div>
              </div>
            </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      user: {
        password: "",
        confirmPassword: "",
        token: this.$route.params.token
      },
      showDismissibleAlert: false,
      message: "",
      alertColor: "danger",
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.user.password != "" &&
        this.user.password == this.user.confirmPassword
      );
    }
  },
  methods: {
    ...mapActions("users", ["resetPassword"]),
    resetPassword() {
      const payload = {
        userDetails: this.user
      };
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$store
            .dispatch("auth/resetPassword", payload)
            .then(response => {
              this.alertColor = 'success'
              this.$vs.loading.close();
              this.showDismissibleAlert = true;
              this.message = response.data.message;
              this.$vs.notify({
                title: "Success",
                text: response.data.message,
                iconPack: "feather",
                icon: "icon-check-circle",
                color: "success"
              });
            })
            .catch(error => {
              this.alertColor = 'danger'
              this.showDismissibleAlert = true;
              this.message = error.message;
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: error.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
              });
            });
        }
      })
    },
  },
}
</script>
